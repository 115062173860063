export default {
  backup: 'Cópia de segurança',
  downloadTheProjectBackupFile: 'Baixe o arquivo de backup do projeto (em json)',
  restore: 'Restaurar',
  incorrectUsrPwd: 'Nome de utilizador ou palavra-passe incorretos',
  askDeleteUser: 'Tem a certeza de que deseja eliminar este utilizador?',
  askDeleteTemp: 'Tem a certeza de que deseja eliminar este modelo?',
  askDeleteEnv: 'Tem a certeza de que deseja eliminar este ambiente?',
  askDeleteInv: 'Tem a certeza de que deseja eliminar este inventário?',
  askDeleteKey: 'Tem a certeza de que deseja eliminar esta chave?',
  askDeleteRepo: 'Tem a certeza de que deseja eliminar este repositório?',
  askDeleteProj: 'Tem a certeza de que deseja eliminar este projeto?',
  askDeleteTMem: 'Tem a certeza de que deseja eliminar este membro da equipa?',
  edit: 'Editar',
  nnew: 'Novo',
  keyFormSshKey: 'Chave SSH',
  keyFormLoginPassword: 'Iniciar sessão com palavra-passe',
  keyFormNone: 'Nenhum',
  incorrectUrl: 'URL incorreto',
  username: 'Nome de utilizador',
  username_required: 'Nome de utilizador obrigatório',
  dashboard: 'Painel de Controlo',
  history: 'Histórico',
  activity: 'Atividade',
  settings: 'Definições',
  signIn: 'Iniciar sessão',
  password: 'Palavra-passe',
  changePassword: 'Alterar palavra-passe',
  editUser: 'Editar Utilizador',
  newProject: 'Novo Projeto',
  close: 'Fechar',
  newProject2: 'Novo projeto...',
  demoMode: 'MODO DE DEMONSTRAÇÃO',
  task: 'Tarefa #{expr}',
  youCanRunAnyTasks: 'Pode executar qualquer tarefa',
  youHaveReadonlyAccess: 'Tem acesso apenas de leitura',
  taskTemplates: 'Modelos de Tarefas',
  inventory: 'Inventário',
  environment: 'Ambiente',
  keyStore: 'Armazenamento de Chaves',
  repositories: 'Repositórios',
  darkMode: 'Modo Escuro',
  team: 'Equipa',
  users: 'Utilizadores',
  editAccount: 'Editar Conta',
  signOut: 'Terminar sessão',
  error: 'Erro',
  refreshPage: 'Atualizar Página',
  relogin: 'Iniciar sessão novamente',
  howToFixSigninIssues: 'Como corrigir problemas de início de sessão',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Primeiro, precisa de acesso ao servidor onde o Semaphore está a correr.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Execute o seguinte comando no servidor para ver os utilizadores existentes:',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: 'Pode alterar a palavra-passe do utilizador existente:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'Ou criar um novo utilizador administrador:',
  close2: 'Fechar',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: 'Não tem uma conta ou não consegue iniciar sessão?',
  password2: 'Palavra-passe',
  cancel: 'Cancelar',
  noViews: 'Sem vistas',
  addView: 'Adicionar vista',
  editEnvironment: 'Editar Ambiente',
  deleteEnvironment: 'Eliminar ambiente',
  environment2: 'Ambiente',
  newEnvironment: 'Novo Ambiente',
  environmentName: 'Nome do Ambiente',
  extraVariables: 'Variáveis Extra',
  enterExtraVariablesJson: 'Introduza JSON de variáveis extra...',
  environmentVariables: 'Variáveis de Ambiente',
  enterEnvJson: 'Introduza JSON de ambiente...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'O ambiente e as variáveis extra devem ser JSON válidos. Exemplo:',
  dashboard2: 'Painel de Controlo',
  ansibleSemaphore: 'Semaphore Ansible',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Lamentamos, mas <%= htmlWebpackPlugin.options.title %> não funciona corretamente sem JavaScript ativado. Por favor, ative-o para continuar.',
  deleteInventory: 'Eliminar inventário',
  newInventory: 'Novo Inventário',
  name: 'Nome',
  userCredentials: 'Credenciais de Utilizador',
  sudoCredentialsOptional: 'Credenciais Sudo (Opcional)',
  type: 'Tipo',
  pathToInventoryFile: 'Caminho para o ficheiro de Inventário',
  enterInventory: 'Introduza o inventário...',
  staticInventoryExample: 'Exemplo de inventário estático:',
  staticYamlInventoryExample: 'Exemplo de inventário YAML estático:',
  keyName: 'Nome da Chave',
  loginOptional: 'Início de sessão (Opcional)',
  usernameOptional: 'Nome de utilizador (Opcional)',
  privateKey: 'Chave Privada',
  override: 'Substituir',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Utilize este tipo de chave para repositórios HTTPS e para playbooks que utilizem ligações não SSH.',
  deleteKey: 'Eliminar chave',
  newKey: 'Nova Chave',
  create: 'Criar',
  newTask: 'Nova Tarefa',
  cantDeleteThe: 'Não é possível eliminar o {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: 'O {objectTitle} não pode ser eliminado porque está a ser utilizado pelos recursos abaixo',
  projectName: 'Nome do Projeto',
  allowAlertsForThisProject: 'Permitir alertas para este projeto',
  telegramChatIdOptional: 'ID de Chat do Telegram (Opcional)',
  maxNumberOfParallelTasksOptional: 'Número Máximo de Tarefas Paralelas (Opcional)',
  deleteRepository: 'Eliminar repositório',
  newRepository: 'Novo Repositório',
  urlOrPath: 'URL ou caminho',
  absPath: 'caminho abs.',
  branch: 'Ramo',
  accessKey: 'Chave de Acesso',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Credenciais para aceder ao repositório Git. Deve ser:',
  ifYouUseGitOrSshUrl: 'se utilizar o URL Git ou SSH.',
  ifYouUseHttpsOrFileUrl: 'se utilizar o URL HTTPS ou ficheiro.',
  none: 'Nenhum',
  ssh: 'SSH',
  deleteProject: 'Eliminar projeto',
  save: 'Guardar',
  deleteProject2: 'Eliminar Projeto',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Depois de eliminar um projeto, não há volta atrás. Por favor, tenha a certeza.',
  name2: 'Nome *',
  title: 'Título *',
  description: 'Descrição',
  required: 'Obrigatório',
  key: '{expr}',
  surveyVariables: 'Variáveis de Inquérito',
  addVariable: 'Adicionar variável',
  columns: 'Colunas',
  buildVersion: 'Versão de Compilação',
  messageOptional: 'Mensagem (Opcional)',
  debug: 'Depuração',
  dryRun: 'Execução a seco',
  diff: 'Diferença',
  advanced: 'Avançado',
  hide: 'Ocultar',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Por favor, permita a substituição do argumento CLI nas definições do Modelo de Tarefa',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Argumentos CLI (matriz JSON). Exemplo: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Iniciado',
  author: 'Autor',
  duration: 'Duração',
  stop: 'Parar',
  deleteTeamMember: 'Eliminar membro da equipa',
  team2: 'Equipa',
  newTeamMember: 'Novo Membro da Equipa',
  user: 'Utilizador',
  administrator: 'Administrador',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Define a versão de início do seu artefacto. Cada execução incrementa a versão do artefacto.',
  forMoreInformationAboutBuildingSeeThe: 'Para mais informações sobre a construção, consulte a',
  taskTemplateReference: 'Referência do Modelo de Tarefa',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Define qual artefacto deve ser implementado quando a tarefa for executada.',
  forMoreInformationAboutDeployingSeeThe: 'Para mais informações sobre a implementação, consulte a',
  taskTemplateReference2: 'Referência do Modelo de Tarefa',
  definesAutorunSchedule: 'Define o cronograma de autorun.',
  forMoreInformationAboutCronSeeThe: 'Para mais informações sobre o cron, consulte a',
  cronExpressionFormatReference: 'Referência do Formato de Expressão Cron',
  startVersion: 'Versão de Início',
  example000: 'Exemplo: 0.0.0',
  buildTemplate: 'Modelo de Compilação',
  autorun: 'Autorun',
  playbookFilename: 'Nome do Ficheiro de Playbook *',
  exampleSiteyml: 'Exemplo: site.yml',
  inventory2: 'Inventário *',
  repository: 'Repositório *',
  environment3: 'Ambiente *',
  vaultPassword: 'Palavra-passe Vault',
  vaultPassword2: 'Palavra-passe Vault',
  view: 'Vista',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Quero executar uma tarefa pelo cron apenas para novos commits de algum repositório',
  repository2: 'Repositório',
  cronChecksNewCommitBeforeRun: 'O cron verifica um novo commit antes da execução',
  readThe: 'Leia o',
  toLearnMoreAboutCron: 'para saber mais sobre o Cron.',
  suppressSuccessAlerts: 'Suprimir alertas de sucesso',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Argumentos CLI (matriz JSON). Exemplo: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Permitir argumentos CLI na Tarefa',
  docs: 'documentação',
  editViews: 'Editar Vistas',
  newTemplate: 'Novo modelo',
  taskTemplates2: 'Modelos de Tarefas',
  all: 'Tudo',
  notLaunched: 'Não lançado',
  by: 'por {user_name} {formatDate}',
  editTemplate: 'Editar Modelo',
  newTemplate2: 'Novo Modelo',
  deleteTemplate: 'Eliminar modelo',
  playbook: 'Playbook',
  email: 'E-mail',
  adminUser: 'Utilizador Administrador',
  sendAlerts: 'Enviar alertas',
  deleteUser: 'Eliminar utilizador',
  newUser: 'Novo Utilizador',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Membro da Equipa',
  taskId: 'ID da Tarefa',
  version: 'Versão',
  status: 'Estado',
  start: 'Iniciar',
  actions: 'Ações',
  alert: 'Alerta',
  admin: 'Administrador',
  role: 'Função',
  external: 'Externo',
  time: 'Hora',
  path: 'Caminho',
  gitUrl: 'URL Git',
  sshKey: 'Chave SSH',
  lastTask: 'Última Tarefa',
  // task2: 'Tarefa',
  // build: 'Compilar',
  // deploy: 'Implementar',
  run: 'Executar',
  add: 'Adicionar',
  password_required: 'Palavra-passe obrigatória',
  name_required: 'Nome obrigatório',
  user_credentials_required: 'Credenciais de utilizador obrigatórias',
  type_required: 'Tipo obrigatório',
  path_required: 'Caminho para o ficheiro de Inventário obrigatório',
  private_key_required: 'Chave privada obrigatória',
  project_name_required: 'Nome do projeto obrigatório',
  repository_required: 'Repositório obrigatório',
  branch_required: 'Ramo obrigatório',
  key_required: 'Chave obrigatória',
  user_required: 'Utilizador obrigatório',
  build_version_required: 'Versão de compilação obrigatória',
  title_required: 'Título obrigatório',
  isRequired: 'é obrigatório',
  mustBeInteger: 'Deve ser um número inteiro',
  mustBe0OrGreater: 'Deve ser 0 ou superior',
  start_version_required: 'Versão de início obrigatória',
  playbook_filename_required: 'Nome do ficheiro de playbook obrigatório',
  inventory_required: 'Inventário obrigatório',
  environment_required: 'Ambiente obrigatório',
  email_required: 'E-mail obrigatório',
  build_template_required: 'Modelo de compilação obrigatório',
  // Task: 'Tarefa',
  // Build: 'Compilar',
  // Deploy: 'Implementar',
  Run: 'Executar',
};
