export default {
  backup: 'Respaldo',
  downloadTheProjectBackupFile: 'Descargue el archivo de copia de seguridad del proyecto (en json)',
  restore: 'Restaurar',
  incorrectUsrPwd: 'Usuario o contraseña incorrecta',
  askDeleteUser: '¿Realmente desea eliminar este usuario?',
  askDeleteTemp: '¿Realmente desea eliminar esta plantilla?',
  askDeleteEnv: '¿Realmente desea eliminar este ambiente?',
  askDeleteInv: '¿Realmente desea eliminar este inventario?',
  askDeleteKey: '¿Realmente desea eliminar esta llave?',
  askDeleteRepo: '¿Realmente desea eliminar este repositorio?',
  askDeleteProj: '¿Realmente desea eliminar este proyecto?',
  askDeleteTMem: '¿Realmente desea quitar a este miembro del equipo?',
  edit: 'Editar',
  nnew: 'Nuevo',
  keyFormSshKey: 'Llave SSH',
  keyFormLoginPassword: 'Ingresa con tu contraseña',
  keyFormNone: 'Ninguno',
  incorrectUrl: 'URL incorrecta',
  username: 'Nombre de Usuario',
  username_required: 'El nombre de usuario es un campo obligatorio',
  dashboard: 'Panel',
  history: 'Historia',
  activity: 'Actividad',
  settings: 'Configuración',
  signIn: 'Iniciar sesión',
  password: 'Contraseña',
  changePassword: 'Cambiar contraseña',
  editUser: 'Editar usuario',
  newProject: 'Nuevo proyecto',
  close: 'Cerrar',
  newProject2: 'Nuevo proyecto...',
  demoMode: 'MODO DEMOSTRACION',
  task: 'Tarea #{expr}',
  youCanRunAnyTasks: 'Puedes ejecutar cualquier tarea',
  youHaveReadonlyAccess: 'Tienes acceso de solo lectura',
  taskTemplates: 'Plantillas de tareas',
  inventory: 'Inventario',
  environment: 'Ambiente',
  keyStore: 'Tienda de llaves',
  repositories: 'Repositorios',
  darkMode: 'Modo oscuro',
  team: 'Equipo',
  users: 'Usuarios',
  editAccount: 'Editar cuenta',
  signOut: 'Cerrar sesión',
  error: 'Error',
  refreshPage: 'Actualizar página',
  relogin: 'Volver a iniciar sesión',
  howToFixSigninIssues: 'Cómo arreglar problemas de inicio de sesión',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Primero, deber tener acceso al servidor donde Semaphore está corriendo.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Ejecute el siguiente comando en el servidor para ver usuarios existentes:',
  semaphoreUserList: 'lista de usuarios de semaphore',
  youCanChangePasswordOfExistingUser: 'Puedes cambiar la contraseña del usuario existente:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login usuario123 --password {unaContraseñaDeEjemplo}',
  orCreateNewAdminUser: 'O crea un nuevo usuario administrador:',
  close2: 'Cerrar',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: '¿No tienes una cuenta o no puedes ingresar?',
  password2: 'Contraseña',
  cancel: 'Cancelar',
  noViews: 'Sin vistas',
  addView: 'Añadir vista',
  editEnvironment: 'Editar Ambiente',
  deleteEnvironment: 'Eliminar Ambiente',
  environment2: 'Ambiente',
  newEnvironment: 'Nuevo Ambiente',
  environmentName: 'Nombre del Ambiente',
  extraVariables: 'Variables extras',
  enterExtraVariablesJson: 'Ingresar variables extras JSON...',
  environmentVariables: 'Variables de Ambiente',
  enterEnvJson: 'Ingresar ambiente JSON...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'El Ambiente y las variables extras deben tene un formato JSON válido. Ejemplo:',
  dashboard2: 'Panel',
  ansibleSemaphore: 'Ansible Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Lo sentimos pero <%= htmlWebpackPlugin.options.title %> no funciona correctamente sin JavaScript habilitado. Por favor habilítelo para continuar.',
  deleteInventory: 'Eliminar inventario',
  newInventory: 'Nuevo Inventario',
  name: 'Nombre',
  userCredentials: 'Credenciales de usuario',
  sudoCredentialsOptional: 'Credenciales Sudo (Opcional)',
  type: 'Tipo',
  pathToInventoryFile: 'Ruta al archivo Inventario',
  enterInventory: 'Ingresar inventario...',
  staticInventoryExample: 'Ejemplo de Inventario estático:',
  staticYamlInventoryExample: 'Ejemplo de Inventario YAML estático:',
  keyName: 'Nombre de la llave',
  loginOptional: 'Inicio de sesión (Opcional)',
  usernameOptional: 'Nombre de Usuario (Opcional)',
  privateKey: 'Llave Privada',
  override: 'Sobreescribir',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Use este tipo de llave para repositorios HTTPS y playbooks que no usen conexiones SSH.',
  deleteKey: 'Eliminar llave',
  newKey: 'Nueva Llave',
  create: 'Crear',
  newTask: 'Nueva Tarea',
  cantDeleteThe: 'No puede eliminar {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} no se puede eliminar porque está siendo usado por uno de los recursos',
  projectName: 'Nombre del Proyecto',
  allowAlertsForThisProject: 'Permitir alertas para este proyecto',
  telegramChatIdOptional: 'ID de Chat para Telegram (Opcional)',
  maxNumberOfParallelTasksOptional: 'Número máximo de tareas en paralelo (Opcional)',
  deleteRepository: 'Eliminar Repositorio',
  newRepository: 'Nuevo Repositorio',
  urlOrPath: 'URL o ruta',
  absPath: 'ruta absoluta',
  branch: 'Rama',
  accessKey: 'Llave de acceso',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Credenciales para acceder al repositorio Git. Debería ser:',
  ifYouUseGitOrSshUrl: 'si usas Git o SSH URL.',
  ifYouUseHttpsOrFileUrl: 'si usas HTTPS o URL de archivo.',
  none: 'Ninguno',
  ssh: 'SSH',
  deleteProject: 'Eliminar Proyecto',
  save: 'Guardar',
  deleteProject2: 'Eliminar Proyecto',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Una vez eliminado el proyecto, no hay vuelta atrás. Por favor esté seguro.',
  name2: 'Nombre *',
  title: 'Título *',
  description: 'Descripción',
  required: 'Requerido',
  key: '{expr}',
  surveyVariables: 'Variables solicitadas',
  addVariable: 'Añadir variable',
  columns: 'Columnas',
  buildVersion: 'Version de Build',
  messageOptional: 'Mensaje (Opcional)',
  debug: 'Debug',
  dryRun: 'Simulacro',
  diff: 'Diferencia',
  advanced: 'Advanzado',
  hide: 'Ocultar',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Por favor, permita sobreescribir el argumento de la línea de comando en las configuraciones de la plantilla de Tareas',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Argumentos CLI (Lista JSON). Ejemplo: [ "-i", "@mi_inventario.sh", "--private-key=/aqui/id_rsa", "-vvvv" ]',
  started: 'Comenzado',
  author: 'Autor',
  duration: 'Duración',
  stop: 'Detener',
  forceStop: 'Detener forzadamente',
  deleteTeamMember: 'Quitar miembro del equipo',
  team2: 'Equipo',
  newTeamMember: 'Nuevo miembro del equipo',
  user: 'Usuario',
  administrator: 'Administrador',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Las definiciones comienzan la versión de tus artefactos. Cada ejecución incrementa la versión del artefacto.',
  forMoreInformationAboutBuildingSeeThe: 'Para más información sobre la compilación vea la',
  taskTemplateReference: 'referencia de Plantila de Tareas',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Define que artefacto debería ser utilizado cuando se ejecuta la tarea.',
  forMoreInformationAboutDeployingSeeThe: 'Para más información sobre la utilización vea la',
  taskTemplateReference2: 'referencia de Plantila de Tareas',
  definesAutorunSchedule: 'Define la recurrencia de la ejecución automática.',
  forMoreInformationAboutCronSeeThe: 'Para más información sobre cron vea la',
  cronExpressionFormatReference: 'referencia del formato de expresiones Cron',
  startVersion: 'Versión de inicio',
  example000: 'Ejemplo: 0.0.0',
  buildTemplate: 'Plantilla de compilación',
  autorun: 'Auto-Ejecución',
  playbookFilename: 'Nombre de archivo del Playbook *',
  exampleSiteyml: 'Ejemplo: sitio.yml',
  inventory2: 'Inventario *',
  repository: 'Repositorio *',
  environment3: 'Ambiente *',
  vaultPassword: 'Contraseña de bóveda',
  vaultPassword2: 'Contraseña de bóveda',
  view: 'Ver',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Quiero ejecutar una tarea mediante Cron solo para nuevos commits del repositorio.',
  repository2: 'Repositorio',
  cronChecksNewCommitBeforeRun: 'Cron debe buscar nuevos commit antes de ejecutarse',
  readThe: 'Lea la',
  toLearnMoreAboutCron: 'para aprender más sobre Cron.',
  suppressSuccessAlerts: 'Silenciar alertas de éxito',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Argumentos CLI (Lista JSON). Ejemplo: [ "-i", "@mi_inventario.sh", "--private-key=/aqui/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Permitir argumentos CLI en Tareas',
  docs: 'documentación',
  editViews: 'Editar Vistas',
  newTemplate: 'Nueva plantilla',
  taskTemplates2: 'Plantillas de tareas',
  all: 'Todos',
  notLaunched: 'No ejecutado',
  by: 'por {user_name} el {formatDate}',
  editTemplate: 'Editar Plantilla',
  newTemplate2: 'Nueva Plantilla',
  deleteTemplate: 'Eliminar Plantilla',
  playbook: 'Playbook',
  email: 'Correo',
  adminUser: 'Usuario Administrador',
  sendAlerts: 'Enviar alertas',
  deleteUser: 'Eliminar Usuario',
  newUser: 'Nuevo Usuario',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Miembro del Equipo',
  taskId: 'ID de Tarea',
  version: 'Versión',
  status: 'Estado',
  start: 'Iniciar',
  actions: 'Acciones',
  alert: 'Alerta',
  admin: 'Administrador',
  role: 'Rol',
  external: 'Externo',
  time: 'Tiempo',
  path: 'Ruta',
  gitUrl: 'URL Git',
  sshKey: 'Llave SSH',
  lastTask: 'Ultima tarea',
  task2: 'Tarea',
  build: 'Compilación',
  deploy: 'Lanzar',
  run: 'Ejecutar',
  add: 'Añadir',
  password_required: 'Es requisito la Contraseña',
  name_required: 'Es requisito el Nombre',
  user_credentials_required: 'Las credenciales del usuario son requisito',
  type_required: 'Es requisito el Tipo',
  path_required: 'Es requisito la ruta al archivo del Inventario',
  private_key_required: 'Es requisito la llave Privada',
  project_name_required: 'Es requisito el Nombre del Proyecto',
  repository_required: 'Es requisito el Repositorio',
  branch_required: 'Es requisito la Rama',
  key_required: 'Es requisito la Llave',
  user_required: 'Es requisito el Usuario',
  build_version_required: 'Es requisito la Versión de Compilación',
  title_required: 'Es requisito el Título',
  isRequired: 'Es requisito',
  mustBeInteger: 'Debe ser un número',
  mustBe0OrGreater: 'Debe ser 0 o más alto',
  start_version_required: 'Es requisito el Inicio de Versión',
  playbook_filename_required: 'Es requisito el nombre de archivo del Playbook',
  inventory_required: 'Es requisito el Inventario',
  environment_required: 'Es requisito el Ambiente',
  email_required: 'Es requisito el Correo',
  build_template_required: 'Es requerida la Plantilla de Compilación',
  Task: 'Tarea',
  Build: 'Compilación',
  Deploy: 'Lanzamiento',
  Run: 'Ejecutar',

};
